import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AITags } from '../tools/const'

const scriptContents = `(function(w,d,s,i,u,a) {
   w._searchie = {widget:i, url:u, user:a};var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async = true;j.src='https://app.membership.io/js/js-popup.js?' + Date.now();f.parentNode.insertBefore(j,f);
})(window, document, 'script', 'KBDamvvDAb', 'https://app.membership.io');`

// Raina is an AI coach, hosted on searchie
const useRaina = () => {
  const { currentUser } = useSelector((state) => state.auth)

  useEffect(() => {
    if (!currentUser) return

    const REQUIRED_TAG_NAME = AITags.RAINA;
    const hasRequiredTag = currentUser.Tags?.some((tag) => tag.name === REQUIRED_TAG_NAME)
    const isAdmin = currentUser.role === 'admin'
    const isAllowed = hasRequiredTag || isAdmin;

    if (!isAllowed) return

    const script = document.createElement('script')
    script.innerHTML = scriptContents
    document.body.appendChild(script)
    return () => document.body.removeChild(script)
  }, [currentUser])
}

export default useRaina
